// constants.js
const TIME_RANGES = {
    HISTORICAL: [
        '24 hours',
        '7 days',
        '30 days',
        '90 days',
        '180 days',
        '1 year',
        '3 years',
        '6 years'
    ],
    REALTIME: [
        '29 minutes',
        '1 hour',
        '3 hours',
        '6 hours',
        '12 hours',
        '24 hours'
    ],
    DAU_MAU: [
        '30 days',

    ]
};

const BASE_URL = 'https://analyticsdata.googleapis.com/v1beta';
const GOOGLE_SCOPE = 'https://www.googleapis.com/auth/analytics.readonly';

// Create an object for default export
const constants = {
    TIME_RANGES,
    BASE_URL,
    GOOGLE_SCOPE
};

// Named exports
export { TIME_RANGES, BASE_URL, GOOGLE_SCOPE };

// Default export
export default constants;