// import React from 'react';
// import HomePage from './components/Home/HomePage';
// import RealtimeAnalyticsPage from './components/Home/realtimeAnalyticsPage';
// import HistoricalAnalyticsPage from './components/Home/historicalAnalyticsPage';

// function App() {
//   return (
//     <AuthProvider>
//       <ProtectedRoute>
//         <HomePage />
//       </ProtectedRoute>
//     </AuthProvider>
//   );
// }


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/Auth/AuthContext';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import HomePage from './components/Home/HomePage';
import RealtimeAnalyticsPage from './components/Home/realtimeAnalyticsPage';
import HistoricalAnalyticsPage from './components/Home/historicalAnalyticsPage';
import DAUMAUAnalyticsPage from './components/Home/dauMauAnalyticsPage';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ProtectedRoute>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/realtime-analytics" element={<RealtimeAnalyticsPage />} />
            <Route path="/historical-analytics" element={<HistoricalAnalyticsPage />} />
            <Route path="/dau-mau-analytics" element={<DAUMAUAnalyticsPage />} />
          </Routes>
        </ProtectedRoute>
      </Router>
    </AuthProvider>
  );
};

export default App;