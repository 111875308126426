

import { useState, useEffect, useCallback } from 'react';
import { useAnalyticsAPI } from './useAnalyticsAPI';
import { getHistoricalDateRange } from '../utils/dateUtils';
import { TIME_RANGES } from '../config/const';
import { processAnalyticsResponse } from '../utils/processAnalyticResponse';
import { useAuth } from '../Auth/AuthContext';

export const useHistoricalAnalytics = (propertyId) => {
    const [data, setData] = useState({});
    const { fetchData, loading, setLoading, error, setError } = useAnalyticsAPI(propertyId);
    const {
        getCurrentUserData, authenticate
    } = useAuth();

    const userData = getCurrentUserData();
    const accessToken = userData.accessToken


    const fetchTimeframeData = useCallback(async (token, timeRange) => {
        try {
            const dateRange = getHistoricalDateRange(timeRange);
            let aggregatedMetrics = {
                activeUsers: 0,
                newUsers: 0,
                engagementMinutes: 0,
                pageViews: 0
            };

            const requestBody = {
                dateRanges: [{
                    startDate: dateRange.startDate,
                    endDate: dateRange.endDate
                }],
                dimensions: [
                    { name: 'date' }
                ],
                metrics: [
                    { name: 'activeUsers' },
                    { name: 'newUsers' },
                    { name: 'userEngagementDuration' },
                    { name: 'screenPageViews' }
                ],
                metricAggregations: ['TOTAL']
            };

            const result = await fetchData('runReport', token, requestBody);
            const processedMetrics = processAnalyticsResponse(result, 'historical');

            aggregatedMetrics.activeUsers = processedMetrics.activeUsers || 0;
            aggregatedMetrics.newUsers = processedMetrics.newUsers || 0;
            aggregatedMetrics.engagementMinutes = Math.round(processedMetrics.userEngagementDuration / 60) || 0;
            aggregatedMetrics.pageViews = processedMetrics.pageViews || 0;

            return aggregatedMetrics;
        } catch (err) {
            console.error(`Error fetching historical data for ${timeRange}:`, err);
            throw err;
        }
    }, [fetchData]);

    const fetchAllTimeframes = useCallback(async () => {
        if (!accessToken) {
            try {
                await authenticate();
                return;
            } catch (err) {
                setError('Authentication failed: ' + err.message);
                return;
            }
        }

        setLoading(true);
        const results = {};

        try {
            for (const timeRange of TIME_RANGES.HISTORICAL) {
                console.log('Fetching historical data for:', timeRange);
                results[timeRange] = await fetchTimeframeData(accessToken, timeRange);
            }
            setData(results);
            setError(null);
        } catch (err) {
            console.error('Error fetching historical data:', err);
            setError(err.message);
            if (err.message.includes('401') || err.message.includes('403')) {
                await authenticate().catch(console.error);
            }
        } finally {
            setLoading(false);
        }
    }, [accessToken, authenticate, fetchTimeframeData, setError, setLoading]);

    useEffect(() => {
        if (propertyId && !accessToken) {
            authenticate();
        }
    }, [propertyId, accessToken, authenticate]);

    useEffect(() => {
        if (accessToken) {
            fetchAllTimeframes();
        }
    }, [accessToken, fetchAllTimeframes]);

    return {
        data,
        loading,
        error,
        refresh: fetchAllTimeframes
    };
};

export default useHistoricalAnalytics;