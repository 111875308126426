


import React, { useState } from 'react';
import { LogOut, User, Plus, Minus, Activity, History, Users, FileChartLine } from 'lucide-react';
import { useAuth } from '../Auth/AuthContext';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { useNavigate } from 'react-router-dom';

const NavigationCard = ({ title, description, icon: Icon, onClick, gradient }) => (
    <Card
        onClick={onClick}
        className="group hover:shadow-2xl transition-all duration-500 hover:-translate-y-1 cursor-pointer bg-white/80 backdrop-blur-sm p-5 relative overflow-hidden"
    >
        <div className={`absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ${gradient}`} />
        <CardContent className="p-8 flex flex-col items-center space-y-6 relative z-10">
            <div className="w-20 h-20 rounded-2xl flex items-center justify-center transform group-hover:scale-110 transition-transform duration-500"
                style={{
                    background: "linear-gradient(135deg, rgba(96, 165, 250, 0.1) 0%, rgba(99, 102, 241, 0.1) 100%)"
                }}>
                <Icon className="w-10 h-10 text-blue-600 group-hover:text-indigo-600 transition-colors duration-500 " />
            </div>
            <div className="space-y-3 text-center">
                <h2 className="text-xl font-bold text-slate-800 group-hover:text-indigo-600 transition-colors duration-300">
                    {title}
                </h2>
                <p className="text-slate-500 text-sm">
                    {description}
                </p>
            </div>
            <Button
                variant="outline"
                className="w-full group-hover:bg-indigo-600 group-hover:text-white border-indigo-200 text-indigo-600 transition-all duration-300"
            >
                View Analytics
            </Button>
        </CardContent>
    </Card>
);

const HomePage = () => {
    const { currentUser,
        getCurrentUserData,
        logout, authenticate } = useAuth();
    const [isUserDetailsExpanded, setIsUserDetailsExpanded] = useState(false);
    const navigate = useNavigate();

    const userData = getCurrentUserData();
    const handleLogout = async () => {
        try {
            await logout(currentUser.userId);
        } catch (error) {
            console.error('Failed to logout:', error);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-slate-50 via-blue-50 to-slate-100 p-6 animate-gradient-slow">
            <style jsx global>{`
                @keyframes gradient {
                    0% { background-position: 0% 50%; }
                    50% { background-position: 100% 50%; }
                    100% { background-position: 0% 50%; }
                }
                .animate-gradient {
                    background-size: 200% 200%;
                    animation: gradient 6s ease infinite;
                }
                .animate-gradient-slow {
                    background-size: 200% 200%;
                    animation: gradient 15s ease infinite;
                }
                @keyframes fadeIn {
                    from { opacity: 0; transform: translateY(10px); }
                    to { opacity: 1; transform: translateY(0); }
                }
                .fade-in {
                    animation: fadeIn 0.5s ease-out forwards;
                }
            `}</style>
            <div className="max-w-7xl mx-auto space-y-8">
                {/* Header */}
                <div className="opacity-0 fade-in" style={{ animationDelay: '0.1s' }}>
                    <Card className="bg-gradient-to-r from-blue-900 to-indigo-900 border-none shadow-2xl p-4">
                        <CardContent className="flex flex-col md:flex-row justify-between items-center p-8">
                            <div className="space-y-3 text-center md:text-left mb-4 md:mb-0">
                                <div className='flex items-center gap-3'>
                                    <FileChartLine className="h-8 w-8 text-indigo-400 animate-pulse" />
                                    <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-200 via-blue-100 to-indigo-200 animate-gradient">
                                        Analytics Dashboard
                                    </h1>

                                </div>

                                <p className="text-blue-200/80">Welcome back, {currentUser?.displayName || 'User'}</p>
                            </div>
                            <Button
                                onClick={handleLogout}
                                className="bg-red-500 hover:bg-red-600  animate-bounce-slow text-white flex items-center gap-2  shadow-lg hover:shadow-red-500/30 transition-all duration-300"
                            >
                                <LogOut className="h-4 w-4" />
                                Sign Out
                            </Button>
                        </CardContent>
                    </Card>
                </div>

                {/* User Details */}
                <div className="opacity-0 fade-in" style={{ animationDelay: '0.2s' }}>
                    <Card className="hover:shadow-xl transition-all duration-300 backdrop-blur-sm bg-white/80">
                        <CardHeader className="flex flex-row items-center justify-between p-6">
                            <div className="flex items-center gap-3">
                                <div className="p-2 bg-indigo-100 rounded-lg ">
                                    <User className="h-5 w-5 text-indigo-600 " />
                                </div>
                                <CardTitle className="text-lg text-slate-800">User Details</CardTitle>
                            </div>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setIsUserDetailsExpanded(!isUserDetailsExpanded)}
                                className="hover:bg-indigo-50 rounded-lg transition-colors"
                            >
                                {isUserDetailsExpanded ? (
                                    <Minus className="h-5 w-5 text-indigo-600" />
                                ) : (
                                    <Plus className="h-5 w-5 text-indigo-600" />
                                )}
                            </Button>
                        </CardHeader>
                        {isUserDetailsExpanded && (
                            <CardContent className="animate-[fadeIn_0.2s_ease-in] p-6">
                                <pre className="bg-slate-50 p-6 rounded-xl overflow-auto border border-indigo-100 text-sm text-slate-700">
                                    {JSON.stringify(userData, null, 2)}
                                </pre>
                            </CardContent>
                        )}
                    </Card>
                </div>

                {/* Analytics Navigation Cards */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 ">
                    <div className="opacity-0 fade-in " style={{ animationDelay: '0.3s' }}>
                        <NavigationCard
                            title="DAU-MAU Analytics"
                            description="Daily and Monthly Active Users"
                            icon={Users}
                            onClick={() => navigate('/dau-mau-analytics')}
                            gradient="bg-gradient-to-br from-blue-500/5 to-indigo-500/5"
                        />
                    </div>
                    <div className="opacity-0 fade-in" style={{ animationDelay: '0.4s' }}>
                        <NavigationCard
                            title="Realtime Analytics"
                            description="Live analytics data from the last 24 hours"
                            icon={Activity}
                            onClick={() => navigate('/realtime-analytics')}
                            gradient="bg-gradient-to-br from-emerald-500/5 to-blue-500/5"
                        />
                    </div>
                    <div className="opacity-0 fade-in" style={{ animationDelay: '0.5s' }}>
                        <NavigationCard
                            title="Historical Analytics"
                            description="Analyze historical data up to 6 years"
                            icon={History}
                            onClick={() => navigate('/historical-analytics')}
                            gradient="bg-gradient-to-br from-indigo-500/5 to-purple-500/5"
                        />
                    </div>
                </div>

                {/* Footer */}
                <div className="opacity-0 fade-in" style={{ animationDelay: '0.6s' }}>
                    <Card className="bg-gradient-to-r from-slate-900 to-blue-900 border-none shadow-lg p-4">
                        <CardContent className="flex items-end justify-end p-6">
                            <p className="text-blue-200/80">
                                Copyright © {new Date().getFullYear()} Abba Info Services, Angamaly, Kerala, India
                            </p>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default HomePage;