// import React, { useEffect } from 'react';
// import { RefreshCw, ArrowLeft } from 'lucide-react';
// import { useRealtimeAnalytics } from '../hooks/realtimeDataHook';
// import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
// import { Button } from '../ui/button';
// import { useNavigate } from 'react-router-dom';
// import QuickStatsRealtime from '../ui/QuickStatsRealtime';
// import DataTable from '../ui/DataTable';

// const RealtimeAnalyticsPage = () => {
//     const navigate = useNavigate();
//     const PROPERTY_ID = '463856483';

//     const {
//         data: realtimeData,
//         loading: realtimeLoading,
//         error: realtimeError,
//         refresh: refreshRealtime
//     } = useRealtimeAnalytics(PROPERTY_ID, 60000);

//     useEffect(() => {
//         const intervalId = setInterval(refreshRealtime, 60000);
//         return () => clearInterval(intervalId);
//     }, [refreshRealtime]);

//     return (
//         <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 p-4 md:p-8">
//             <div className="max-w-7xl mx-auto space-y-6">
//                 {/* Header */}
//                 <Card className="shadow-xl shadow-indigo-200 border border-blue-600">
//                     <CardContent className="flex flex-col md:flex-row justify-between items-center p-6">
//                         <div className="space-y-2 text-center md:text-left mb-4 md:mb-0">
//                             <div className="flex items-center gap-4">
//                                 <Button
//                                     onClick={() => navigate('/')}
//                                     variant="ghost"
//                                     className="p-2"
//                                 >
//                                     <ArrowLeft className="h-5 w-5" />
//                                 </Button>
//                                 <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 p-3 bg-clip-text text-transparent">
//                                     Realtime Analytics
//                                 </h1>
//                             </div>
//                             <p className="text-gray-500">Live analytics data from the last 24 hours</p>
//                         </div>
//                         <Button
//                             onClick={refreshRealtime}
//                             variant="outline"
//                             className="flex items-center gap-2"
//                             disabled={realtimeLoading}
//                         >
//                             <RefreshCw className={`h-4 w-4 ${realtimeLoading ? 'animate-spin' : ''}`} />
//                             Refresh Data
//                         </Button>
//                     </CardContent>
//                 </Card>

//                 {/* Quick Stats */}
//                 <QuickStatsRealtime realtimeData={realtimeData} />

//                 {/* Real-time Analytics Table */}
//                 <Card className="hover:shadow-xl transition-shadow duration-300">
//                     <CardHeader>
//                         <CardTitle className="text-xl">Detailed Analytics</CardTitle>
//                     </CardHeader>
//                     <CardContent>
//                         <DataTable
//                             data={realtimeData}
//                             loading={realtimeLoading}
//                             error={realtimeError}
//                             onRetry={refreshRealtime}
//                             variant="realtime"
//                         />
//                     </CardContent>
//                 </Card>
//             </div>
//         </div>
//     );
// };

// export default RealtimeAnalyticsPage;

import React, { useEffect } from 'react';
import { RefreshCw, ArrowLeft, Activity, Clock } from 'lucide-react';
import { useRealtimeAnalytics } from '../hooks/realtimeDataHook';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button'
import { useNavigate } from 'react-router-dom';
import QuickStatsRealtime from '../ui/QuickStatsRealtime';
import DataTable from '../ui/DataTable';


const REFRESH_INTERVAL = 60000;

const LiveIndicator = () => (
    <div className="flex items-center gap-2 bg-green-500/10 px-3 py-1 rounded-full">
        <div className="h-2 w-2 rounded-full bg-green-500 animate-pulse" />
        <span className="text-green-500 text-sm font-medium">LIVE</span>
    </div>
);

const PageHeader = ({ onBack, onRefresh, isLoading }) => (
    <Card className="bg-gradient-to-r from-emerald-900 to-blue-900 border-none shadow-2xl transform hover:scale-[1.01] transition-all duration-300 p-6">
        <CardContent className="flex flex-col md:flex-row justify-between items-center p-8">
            <div className="space-y-3 text-center md:text-left mb-4 md:mb-0">
                <div className="flex items-center gap-4">
                    <Button
                        onClick={onBack}
                        variant="ghost"
                        className="p-2 text-white/80 hover:text-white hover:bg-white/10 transition-colors"
                    >
                        <ArrowLeft className="h-5 w-5" />
                    </Button>
                    <div className="flex items-center gap-3">
                        <Activity className="h-8 w-8 text-emerald-400 animate-pulse" />
                        <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-emerald-200 via-blue-100 to-emerald-300 animate-gradient">
                            Realtime Analytics
                        </h1>
                    </div>
                    <LiveIndicator />
                </div>
                <div className="flex items-center gap-2 text-emerald-200/80">
                    <Clock className="h-4 w-4" />
                    <p>Live analytics data from the last 24 hours</p>
                </div>
            </div>
            <Button
                onClick={onRefresh}
                className="bg-emerald-500 hover:bg-emerald-600 text-white flex items-center gap-2 shadow-lg hover:shadow-emerald-500/30 transition-all duration-300"
                disabled={isLoading}
            >
                <RefreshCw className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
                Refresh Data
            </Button>
        </CardContent>
    </Card>
);

const DataSection = ({ data, isLoading, error, onRetry }) => (
    <Card className="backdrop-blur-sm bg-white/90 shadow-xl hover:shadow-2xl transition-all duration-300 border border-slate-200">
        <CardHeader className="border-b border-slate-100">
            <div className="flex items-center justify-between">
                <CardTitle className="text-xl font-semibold bg-gradient-to-r from-emerald-900 to-blue-900 bg-clip-text text-transparent">
                    Detailed Analytics
                </CardTitle>
                <div className="flex items-center gap-2 text-sm text-slate-500">
                    <Clock className="h-4 w-4" />
                    <span>Auto-refreshes every minute</span>
                </div>
            </div>
        </CardHeader>
        <CardContent className="p-6">
            <DataTable
                data={data}
                loading={isLoading}
                error={error}
                onRetry={onRetry}
                variant="realtime"
            />
        </CardContent>
    </Card>
);

const RealtimeAnalyticsPage = () => {
    const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID
    const navigate = useNavigate();
    const {
        data: realtimeData,
        loading: realtimeLoading,
        error: realtimeError,
        refresh: refreshRealtime
    } = useRealtimeAnalytics(PROPERTY_ID, REFRESH_INTERVAL);




    useEffect(() => {
        const intervalId = setInterval(refreshRealtime, REFRESH_INTERVAL);
        return () => clearInterval(intervalId);
    }, [refreshRealtime]);

    return (
        <div className="min-h-screen bg-gradient-to-b from-slate-50 via-emerald-50 to-slate-100 p-4 md:p-8 animate-gradient-slow">
            <style jsx global>{`
        @keyframes gradient {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }
        .animate-gradient {
          background-size: 200% 200%;
          animation: gradient 6s ease infinite;
        }
        .animate-gradient-slow {
          background-size: 200% 200%;
          animation: gradient 15s ease infinite;
        }
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .fade-in {
          animation: fadeIn 0.5s ease-out forwards;
        }
        @keyframes pulse {
          0%, 100% { opacity: 1; }
          50% { opacity: 0.5; }
        }
        .animate-pulse {
          animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }
      `}</style>
            <div className="max-w-7xl mx-auto space-y-8">
                <div className="opacity-0 fade-in" style={{ animationDelay: '0.1s' }}>
                    <PageHeader
                        onBack={() => navigate('/')}
                        onRefresh={refreshRealtime}
                        isLoading={realtimeLoading}
                    />
                </div>

                <div className="opacity-0 fade-in" style={{ animationDelay: '0.3s' }}>
                    <Card className="bg-gradient-to-br from-white via-emerald-50 to-white border border-emerald-100 p-7 shadow-xl hover:shadow-2xl transition-all duration-300">
                        <CardContent className="p-6">
                            <QuickStatsRealtime realtimeData={realtimeData} />
                        </CardContent>
                    </Card>
                </div>

                <div className="opacity-0 fade-in" style={{ animationDelay: '0.5s' }}>
                    <DataSection
                        data={realtimeData}
                        isLoading={realtimeLoading}
                        error={realtimeError}
                        onRetry={refreshRealtime}
                    />
                </div>
            </div>
        </div>
    );
};

export default RealtimeAnalyticsPage;