// import React from 'react';
// import { RefreshCw, ArrowLeft } from 'lucide-react';
// import { useHistoricalAnalytics } from '../hooks/historicalDataHook';
// import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
// import { Button } from '../ui/button';
// import { useNavigate } from 'react-router-dom';
// import DataTable from '../ui/DataTable';
// import QuickStatsHistorical from '../ui/QuickstatsHistorical';

// const HistoricalAnalyticsPage = () => {
//     const navigate = useNavigate();
//     const PROPERTY_ID = '463856483';

//     const {
//         data: historicalData,
//         loading: historicalLoading,
//         error: historicalError,
//         refresh: refreshHistorical
//     } = useHistoricalAnalytics(PROPERTY_ID);

//     return (
//         <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 p-4 md:p-8">
//             <div className="max-w-7xl mx-auto space-y-6">
//                 {/* Header */}
//                 <Card className="shadow-xl shadow-indigo-200 border border-blue-600 ">
//                     <CardContent className="flex flex-col md:flex-row justify-between items-center p-6">
//                         <div className="space-y-2 text-center md:text-left mb-4 md:mb-0 ">
//                             <div className="flex items-center gap-4">
//                                 <Button
//                                     onClick={() => navigate('/')}
//                                     variant="ghost"
//                                     className="p-2"
//                                 >
//                                     <ArrowLeft className="h-5 w-5" />
//                                 </Button>
//                                 <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600  p-3 to-indigo-600 bg-clip-text text-transparent">
//                                     Historical Analytics
//                                 </h1>
//                             </div>
//                             <p className="text-gray-500">Historical data analysis up to 6 years</p>
//                         </div>
//                         <Button
//                             onClick={refreshHistorical}
//                             variant="outline"
//                             className="flex items-center gap-2"
//                             disabled={historicalLoading}
//                         >
//                             <RefreshCw className={`h-4 w-4 ${historicalLoading ? 'animate-spin' : ''}`} />
//                             Refresh Data
//                         </Button>
//                     </CardContent>
//                 </Card>

//                 {/* Quick Stats */}
//                 <QuickStatsHistorical realtimeData={historicalData} />

//                 {/* Historical Analytics Table */}
//                 <Card className="hover:shadow-xl transition-shadow duration-300">
//                     <CardHeader>
//                         <CardTitle className="text-xl">Historical Data</CardTitle>
//                     </CardHeader>
//                     <CardContent>
//                         <DataTable
//                             data={historicalData}
//                             loading={historicalLoading}
//                             error={historicalError}
//                             onRetry={refreshHistorical}
//                             variant="historical"
//                         />
//                     </CardContent>
//                 </Card>
//             </div>
//         </div>
//     );
// };

// export default HistoricalAnalyticsPage;
import React from 'react';
import { RefreshCw, ArrowLeft, TrendingUp } from 'lucide-react';
import QuickStatsHistorical from '../ui/QuickstatsHistorical';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';
import DataTable from '../ui/DataTable';
import { useHistoricalAnalytics } from '../hooks/historicalDataHook';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';;





const PageHeader = ({ onBack, onRefresh, isLoading }) => (
    <Card className="bg-gradient-to-r from-slate-900 to-blue-900 border-none shadow-2xl transform hover:scale-[1.01] transition-all duration-300 p-6">
        <CardContent className="flex flex-col md:flex-row justify-between items-center p-10">
            <div className="space-y-3 text-center md:text-left mb-4 md:mb-0">
                <div className="flex items-center gap-4">
                    <Button
                        onClick={onBack}
                        variant="ghost"
                        className="p-2 text-white/80 hover:text-white hover:bg-white/10 transition-colors"
                    >
                        <ArrowLeft className="h-5 w-5" />
                    </Button>
                    <div className="flex items-center gap-3">
                        <TrendingUp className="h-8 w-8 text-blue-400" />
                        <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-200 via-blue-100 to-blue-300 animate-gradient">
                            Historical Analytics
                        </h1>
                    </div>
                </div>
                <p className="text-blue-200/80">
                    Comprehensive historical data analysis spanning 6 years
                </p>
            </div>
            <Button
                onClick={onRefresh}
                className="bg-blue-500 hover:bg-blue-600 text-white flex items-center gap-2 shadow-lg hover:shadow-blue-500/30 transition-all duration-300"
                disabled={isLoading}
            >
                <RefreshCw className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
                Refresh Data
            </Button>
        </CardContent>
    </Card>
);

const DataSection = ({ data, isLoading, error, onRetry }) => (
    <Card className="backdrop-blur-sm bg-white/90 shadow-xl hover:shadow-2xl transition-all duration-300 border border-slate-200">
        <CardHeader className="border-b border-slate-100">
            <CardTitle className="text-xl font-semibold bg-gradient-to-r from-slate-900 to-blue-900 bg-clip-text text-transparent">
                Historical Data
            </CardTitle>
        </CardHeader>
        <CardContent className="p-6">
            <DataTable
                data={data}
                loading={isLoading}
                error={error}
                onRetry={onRetry}
                variant="historical"
            />
        </CardContent>
    </Card>
);

const HistoricalAnalyticsPage = () => {
    const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID
    const navigate = useNavigate();
    const {
        data: historicalData,
        loading: historicalLoading,
        error: historicalError,
        refresh: refreshHistorical
    } = useHistoricalAnalytics(PROPERTY_ID);




    const handleBack = () => navigate('/');

    return (
        <div className="min-h-screen bg-gradient-to-b from-slate-50 via-blue-50 to-slate-100 p-4 md:p-8 animate-gradient-slow">
            <style jsx global>{`
        @keyframes gradient {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }
        .animate-gradient {
          background-size: 200% 200%;
          animation: gradient 6s ease infinite;
        }
        .animate-gradient-slow {
          background-size: 200% 200%;
          animation: gradient 15s ease infinite;
        }
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        .fade-in {
          animation: fadeIn 0.5s ease-out forwards;
        }
      `}</style>
            <div className="max-w-7xl mx-auto space-y-8">
                <div className="opacity-0 fade-in" style={{ animationDelay: '0.1s' }}>
                    <PageHeader
                        onBack={handleBack}
                        onRefresh={refreshHistorical}
                        isLoading={historicalLoading}
                    />
                </div>

                <div className="opacity-0 fade-in" style={{ animationDelay: '0.3s' }}>
                    <Card className="bg-gradient-to-br from-white via-blue-50 to-white border border-blue-100 shadow-xl hover:shadow-2xl p-7 transition-all duration-300">
                        <CardContent className="p-6">
                            <QuickStatsHistorical realtimeData={historicalData} />
                        </CardContent>
                    </Card>
                </div>

                <div className="opacity-0 fade-in" style={{ animationDelay: '0.5s' }}>
                    <DataSection
                        data={historicalData}
                        isLoading={historicalLoading}
                        error={historicalError}
                        onRetry={refreshHistorical}
                    />
                </div>
            </div>

        </div>
    );
};

export default HistoricalAnalyticsPage;