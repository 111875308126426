// import React from 'react';
// import { useAuth } from './AuthContext';
// import LoginPage from './LoginPage';

// const ProtectedRoute = ({ children }) => {
//     const { user, loading } = useAuth();

//     if (loading) {
//         return (
//             <div className="flex items-center justify-center min-h-screen">
//                 <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-500" />
//             </div>
//         );
//     }

//     if (!user) {
//         return <LoginPage />;
//     }

//     return children;
// };

// export default ProtectedRoute;
import React from 'react';
import { useAuth } from './AuthContext';
import LoginPage from './LoginPage';

const ProtectedRoute = ({ children }) => {
    const { currentUser, loading } = useAuth();

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-500"></div>
            </div>
        );
    }

    if (!currentUser) {
        return <LoginPage />;
    }

    return children;
};

export default ProtectedRoute;