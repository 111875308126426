

import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { setUserSession, getUserSession, clearUserSession } from '../session/sessionUtils';

// Create context
const AuthContext = createContext(null);

// Google OAuth configuration
// const GOOGLE_CLIENT_ID = "1041709408-7hpihl85e5t1lq0qi03g4ph2scsr43kq.apps.googleusercontent.com";
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GOOGLE_SCOPES = [
    'email',
    'profile',
    'openid',
    'https://www.googleapis.com/auth/analytics.readonly',
    'https://www.googleapis.com/auth/analytics'

].join(' ');

export function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Initialize Google OAuth
    useEffect(() => {
        const loadGoogleAuth = async () => {

            try {
                await new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.src = 'https://accounts.google.com/gsi/client';
                    script.async = true;
                    script.defer = true;
                    script.onload = resolve;
                    document.body.appendChild(script);
                });
                setLoading(false);
            } catch (error) {
                console.error('Error loading Google OAuth:', error);
                setError('Failed to initialize Google authentication');
                setLoading(false);
            }
        };

        loadGoogleAuth();
    }, []);

    // Handle Google OAuth response
    const handleGoogleResponse = async (response) => {
        if (response.error) {
            setError(response.error);
            throw new Error(response.error);
        }

        try {
            // Get user info using the access token
            const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: {
                    'Authorization': `Bearer ${response.access_token}`
                }
            });

            if (!userInfoResponse.ok) {
                throw new Error('Failed to fetch user data');
            }

            const userInfo = await userInfoResponse.json();

            // Prepare user data with consistent structure
            const userData = {
                uid: userInfo.sub,
                email: userInfo.email,
                displayName: userInfo.name,
                firstName: userInfo.given_name,
                lastName: userInfo.family_name,
                photoURL: userInfo.picture,
                emailVerified: userInfo.email_verified,
                accessToken: response.access_token,
                provider: 'google',
                lastLoginAt: new Date().toISOString()
            };

            // Store in session and update state
            setUserSession(userData);
            setCurrentUser(userData);
            return userData;
        } catch (error) {
            const errorMessage = error.message || 'Failed to process Google sign-in';
            console.error('Google sign-in error:', error);
            setError(errorMessage);
            throw error;
        }
    };

    // Login with Google
    const loginWithGoogle = () => {

        return new Promise((resolve, reject) => {
            try {
                const tokenClient = window.google.accounts.oauth2.initTokenClient({
                    client_id: GOOGLE_CLIENT_ID,
                    scope: GOOGLE_SCOPES,
                    callback: async (response) => {
                        try {
                            const userData = await handleGoogleResponse(response);
                            resolve(userData);
                        } catch (error) {
                            reject(error);
                        }
                    },
                });

                tokenClient.requestAccessToken();
            } catch (error) {
                console.error('Google sign-in error:', error);
                setError('Failed to initialize Google sign-in');
                reject(error);
            }
        });
    };

    // Logout
    const logout = async () => {
        try {
            // Revoke Google access token if it exists
            if (currentUser?.accessToken) {
                await fetch(`https://oauth2.googleapis.com/revoke?token=${currentUser.accessToken}`, {
                    method: 'POST',
                });
            }

            // Clear local session
            if (currentUser?.uid) {
                clearUserSession(currentUser.uid);
            }

            setCurrentUser(null);

            // Optional: Clear any persisted state
            window.location.reload();
        } catch (error) {
            console.error('Logout error:', error);
            setError('Failed to sign out');
            throw error;
        }
    };

    // Get current user data
    const getCurrentUserData = () => {
        if (!currentUser?.uid) return null;
        try {
            return getUserSession(currentUser.uid) || currentUser;
        } catch (error) {
            console.error('Error getting user data:', error);
            return null;
        }
    };

    // Update user data
    const updateUserData = (newData) => {
        if (!currentUser?.uid || !newData) return false;

        try {
            const currentData = getCurrentUserData();
            const updatedData = {
                ...currentData,
                ...newData,
                lastUpdated: new Date().toISOString()
            };

            setUserSession(updatedData);
            setCurrentUser(prevUser => ({ ...prevUser, ...updatedData }));
            return true;
        } catch (error) {
            console.error('Error updating user data:', error);
            setError('Failed to update user data');
            return false;
        }
    };

    const authenticate = async () => {
        if (!currentUser?.accessToken) {
            throw new Error('No access token available. Please log in.');
        }

        try {
            // Verify the token is still valid
            const response = await fetch('https://www.googleapis.com/oauth2/v3/tokeninfo', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${currentUser.accessToken}`
                }
            });

            if (!response.ok) {
                throw new Error('Token validation failed');
            }

            setIsAuthenticated(true);
            return currentUser.accessToken;
        } catch (error) {
            setIsAuthenticated(false);
            throw new Error('Authentication failed. Please log in again.');
        }
    };

    // Check for existing session on mount
    useEffect(() => {
        const checkExistingSession = () => {
            try {
                const user = getCurrentUserData();
                if (user) {
                    setCurrentUser(user);
                }
            } catch (error) {
                console.error('Error checking existing session:', error);
            } finally {
                setLoading(false);
            }
        };

        checkExistingSession();
    }, []);

    // Reset error utility
    const resetError = () => setError(null);

    // Memoize context value to prevent unnecessary rerenders
    const value = useMemo(() => ({
        currentUser,
        loading,
        error,
        loginWithGoogle,
        logout,
        getCurrentUserData,
        updateUserData,
        authenticate,
        resetError,
        isAuthenticated: !!currentUser
    }), [currentUser, loading, error]);

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}