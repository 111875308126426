// sessionUtils.js

/**
 * Type definitions for better code understanding and IDE support
 * @typedef {Object} UserSessionData
 * @property {string} userId - Unique identifier for the user
 * @property {string} email - User's email address
 * @property {string|null} displayName - User's display name
 * @property {string|null} photoURL - User's profile photo URL
 * @property {string|null} phoneNumber - User's phone number
 * @property {boolean} emailVerified - Email verification status
 * @property {string} accessToken - Current access token
 * @property {string} createdAt - Account creation timestamp
 * @property {Object} providerData - Authentication provider details
 * @property {Object} authConfig - Firebase authentication configuration
 * @property {Object} tokenManager - Token management data
 */

/**
 * Validates and sanitizes the user data object
 * @param {Object} userData - Raw user data from Firebase
 * @returns {Object|null} - Sanitized user data or null if invalid
 */
const sanitizeUserData = (userData) => {
    if (!userData) {
        console.error('No user data provided');
        return null;
    }



    // Handle both Firebase User object and custom user data structures
    const uid = userData.uid || userData.userId;
    if (!uid) {
        console.error('No user ID found in user data' + userData);
        return null;
    }

    // Extract token data from different possible locations
    const tokenData = userData.stsTokenManager || userData.tokenManager || {};
    const accessToken = userData.accessToken || tokenData.accessToken;

    if (!accessToken) {
        console.warn('No access token found in user data');
    }

    // Extract provider data safely
    const providerData = Array.isArray(userData.providerData) && userData.providerData[0]
        ? userData.providerData[0]
        : userData.providerData || {};

    return {
        userId: uid,
        email: userData.email || providerData.email || null,
        displayName: userData.displayName || providerData.displayName || null,
        photoURL: userData.photoURL || providerData.photoURL || null,
        phoneNumber: userData.phoneNumber || null,
        emailVerified: Boolean(userData.emailVerified),
        accessToken: accessToken || null,
        createdAt: userData.metadata?.createdAt || userData.createdAt || new Date().toISOString(),
        lastLoginAt: userData.metadata?.lastLoginAt || userData.lastLoginAt || new Date().toISOString(),
        providerData: {
            providerId: providerData.providerId || 'unknown',
            federatedId: providerData.uid || null,
            email: providerData.email || null,
            displayName: providerData.displayName || null,
            photoURL: providerData.photoURL || null
        },
        authConfig: {
            apiKey: userData.auth?.apiKey || process.env.NEXT_PUBLIC_FIREBASE_API_KEY || null,
            authDomain: userData.auth?.authDomain || process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || null,
            appName: userData.auth?.appName || 'default'
        },
        tokenManager: {
            refreshToken: tokenData.refreshToken || null,
            accessToken: tokenData.accessToken || accessToken || null,
            expirationTime: tokenData.expirationTime || null
        }
    };
};

/**
 * Sets user session data in sessionStorage with enhanced security and validation
 * @param {Object} userData - Raw user data from Firebase
 * @returns {boolean} - Success status of the operation
 */
export const setUserSession = (userData) => {
    try {
        const sanitizedData = sanitizeUserData(userData);
        if (!sanitizedData || !sanitizedData.userId) {
            console.log("user session not set - missing userId");
            return false;
        }

        // Store the sanitized data under the user's ID
        sessionStorage.setItem(
            sanitizedData.userId,
            JSON.stringify(sanitizedData)
        );


        return true;
    } catch (error) {
        console.error('Error setting session data:', error);
        return false;
    }
};

/**
 * Updates existing user session data or creates new session if none exists
 * @param {string} userId - The user's ID
 * @param {Object} updateData - New data to update in the session
 * @returns {boolean} - Success status of the operation
 */
export const updateUserSession = (userId, updateData) => {
    try {
        const currentSession = getUserSession(userId);
        if (!currentSession) {
            return setUserSession({
                userId,
                ...updateData
            });
        }

        const updatedSession = {
            ...currentSession,
            ...updateData,
            userId, // Ensure userId is preserved
            lastUpdated: new Date().toISOString()
        };

        return setUserSession(updatedSession);
    } catch (error) {
        console.error('Error updating session:', error);
        return false;
    }
};

/**
 * Retrieves the current user session data
 * @returns {UserSessionData|null} - User session data or null if not found
 */
export const getUserSession = (userId) => {

    try {
        const userData = sessionStorage.getItem(userId);
        return userData ? JSON.parse(userData) : null;
    } catch (error) {
        console.error('Error getting session data:', error);
        return null;
    }

};

/**
 * Clears the user session data with additional cleanup
 * @returns {boolean} - Success status of the operation
 */
export const clearUserSession = (userId) => {
    try {
        sessionStorage.removeItem(userId);
        return true;
    } catch (error) {
        console.error('Error clearing session data:', error);
        return false;
    }
};


/**
 * Checks if the user session is valid by verifying token expiration
 * @param {string} userId - The user's ID
 * @returns {boolean} - Whether the session is valid
 */
export const isSessionValid = (userId) => {
    try {
        const session = getUserSession(userId);
        if (!session?.tokenManager) return false;

        const { expirationTime, accessToken } = session.tokenManager;
        if (!accessToken) return false;

        // Check if token exists and is not expired
        return expirationTime ? expirationTime > Date.now() : true;
    } catch (error) {
        console.error('Error checking session validity:', error);
        return false;
    }
};