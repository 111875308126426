import React from 'react';
import { Users, Activity, Eye } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from './card';

const QuickStatsHistorical = ({ historicalData }) => {
    const quickStats = [
        {
            title: 'Active Users',
            icon: Users,
            color: 'blue',
            value: historicalData?.['30 days']?.activeUsers || '0',
            description: 'Currently active users'
        },
        {
            title: 'Events',
            icon: Activity,
            color: 'indigo',
            value: historicalData?.['30 days']?.newUsers || '0',
            description: 'Total events count'
        },
        {
            title: 'Page Views',
            icon: Eye,
            color: 'purple',
            value: historicalData?.['30 days']?.pageViews || '0',
            description: 'Current page views'
        }
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {quickStats.map((stat, index) => (
                <Card key={index} className="hover:shadow-xl transition-all duration-300">
                    <CardHeader className="flex flex-row items-center justify-between pb-2">
                        <CardTitle className="text-sm font-medium text-gray-500">
                            {stat.title}
                        </CardTitle>
                        <div className="p-2 rounded-lg bg-blue-100">
                            <stat.icon className="h-5 w-5 text-blue-600" />
                        </div>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-1">
                            <p className="text-2xl font-bold">{stat.value}</p>
                            <p className="text-sm text-gray-500">{stat.description}</p>
                        </div>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

export default QuickStatsHistorical;