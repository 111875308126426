// hooks/useAnalyticsAPI.js
import { useState, useCallback } from 'react';
import { BASE_URL } from '../config/const';

// API error types for better error handling
const API_ERROR_TYPES = {
    AUTH: 'AUTH_ERROR',
    RATE_LIMIT: 'RATE_LIMIT_ERROR',
    INVALID_REQUEST: 'INVALID_REQUEST',
    SERVER_ERROR: 'SERVER_ERROR',
    NETWORK_ERROR: 'NETWORK_ERROR'
};

/**
 * Maximum number of retry attempts for failed requests
 */
const MAX_RETRIES = 3;

/**
 * Delay between retries in milliseconds (with exponential backoff)
 */
const RETRY_DELAY = 1000;

export const useAnalyticsAPI = (propertyId) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Determines the type of error from the response
     * @param {Response} response - Fetch Response object
     * @param {Object} errorData - Error data from response
     * @returns {string} Error type
     */
    const getErrorType = (response, errorData) => {
        const status = response.status;
        const errorMessage = errorData?.error?.message || '';

        if (status === 401 || status === 403) {
            return API_ERROR_TYPES.AUTH;
        }
        if (status === 429) {
            return API_ERROR_TYPES.RATE_LIMIT;
        }
        if (status >= 400 && status < 500) {
            return API_ERROR_TYPES.INVALID_REQUEST;
        }
        if (status >= 500) {
            return API_ERROR_TYPES.SERVER_ERROR;
        }
        return API_ERROR_TYPES.NETWORK_ERROR;
    };

    /**
     * Handles API errors with proper error messages and actions
     * @param {Response} response - Fetch Response object
     * @param {Object} errorData - Error data from response
     * @throws {Error} Formatted error with type and message
     */
    const handleApiError = async (response, errorData) => {
        const errorType = getErrorType(response, errorData);
        const errorMessage = errorData?.error?.message || 'Unknown error occurred';

        const error = new Error(errorMessage);
        error.type = errorType;
        error.status = response.status;
        error.endpoint = response.url;

        // Log error for debugging
        console.error('API Error:', {
            type: errorType,
            status: response.status,
            message: errorMessage,
            endpoint: response.url
        });

        throw error;
    };

    /**
     * Makes an API request with retry logic
     * @param {string} endpoint - API endpoint
     * @param {string} token - Access token
     * @param {Object} body - Request body
     * @param {number} retryCount - Current retry attempt
     * @returns {Promise<Object>} API response
     */
    const makeRequest = async (endpoint, token, body, retryCount = 0) => {
        try {
            const response = await fetch(
                `${BASE_URL}/properties/${propertyId}:${endpoint}`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body)
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                await handleApiError(response, errorData);
            }

            return await response.json();
        } catch (error) {
            // Handle retry logic
            if (retryCount < MAX_RETRIES &&
                (error.type === API_ERROR_TYPES.SERVER_ERROR ||
                    error.type === API_ERROR_TYPES.NETWORK_ERROR)) {
                const delay = RETRY_DELAY * Math.pow(2, retryCount);
                await new Promise(resolve => setTimeout(resolve, delay));
                return makeRequest(endpoint, token, body, retryCount + 1);
            }

            throw error;
        }
    };

    /**
     * Fetches data from the Analytics API
     * @param {string} endpoint - API endpoint
     * @param {string} token - Access token
     * @param {Object} body - Request body
     * @returns {Promise<Object>} Processed API response
     */
    const fetchData = useCallback(async (endpoint, token, body) => {
        if (!token) {
            throw new Error('No access token provided');
        }

        if (!propertyId) {
            throw new Error('No property ID provided');
        }

        try {
            const response = await makeRequest(endpoint, token, body);

            // Validate response structure
            if (!response || typeof response !== 'object') {
                throw new Error('Invalid response format');
            }

            return response;
        } catch (err) {
            const errorMessage = err.type ?
                `${err.type}: ${err.message}` :
                `API Error: ${err.message}`;

            // Set error state
            setError(errorMessage);

            // Rethrow error for handling in the calling hook
            throw new Error(errorMessage);
        }
    }, [propertyId]);

    /**
     * Clears any existing error state
     */
    const clearError = useCallback(() => {
        setError(null);
    }, []);

    return {
        fetchData,
        loading,
        setLoading,
        error,
        setError,
        clearError
    };
};