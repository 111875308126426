
import React from 'react';
import { RefreshCw, ArrowLeft, Users, Activity, TrendingUp, ChartBar } from 'lucide-react';
import { useEngagementRatios } from '../hooks/dauMauHook';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { useNavigate } from 'react-router-dom';




const calculateAverageEngagement = (metrics) => {
    if (!metrics?.dauPerWau || !metrics?.dauPerMau) return '0';
    return ((metrics.dauPerWau + metrics.dauPerMau) / 2).toFixed(2);
};

const MetricCard = ({ title, value, icon: Icon, loading, subtitle, trend }) => (
    <Card className="group bg-white/80 backdrop-blur-sm hover:bg-white transition-all duration-500 hover:shadow-2xl hover:-translate-y-1 p-8">
        <CardContent className="p-6 relative overflow-hidden">
            <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-indigo-500/10 to-blue-500/5 rounded-bl-full transform translate-x-8 -translate-y-8 group-hover:translate-x-4 group-hover:-translate-y-4 transition-transform duration-500" />

            <div className="flex items-center justify-between">
                <div className="space-y-3">
                    <div className="flex items-center gap-2">
                        <p className="text-sm font-medium text-slate-600">{title}</p>
                        {trend && (
                            <span className={`text-xs px-2 py-0.5 rounded-full ${trend > 0 ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
                                }`}>
                                {trend > 0 ? '+' : ''}{trend}%
                            </span>
                        )}
                    </div>
                    <div className="text-3xl font-bold bg-gradient-to-r from-indigo-900 to-blue-900 bg-clip-text text-transparent">
                        {loading ? (
                            <div className="h-9 w-24 bg-slate-200 animate-pulse rounded" />
                        ) : (
                            value
                        )}
                    </div>
                    {subtitle && (
                        <p className="text-sm text-slate-500 font-medium">{subtitle}</p>
                    )}
                </div>
                <div className="bg-gradient-to-br from-indigo-500 to-blue-600 p-3 rounded-xl shadow-lg group-hover:shadow-indigo-500/25 transition-all duration-300">
                    <Icon className="w-6 h-6 text-white" />
                </div>
            </div>
        </CardContent>
    </Card>
);

const PageHeader = ({ onRefresh, loading, onBack, title, subtitle }) => (
    <Card className="bg-gradient-to-r from-indigo-900 to-blue-900 border-none shadow-2xl transform hover:scale-[1.01] transition-all duration-300 p-6">
        <CardContent className="flex flex-col md:flex-row justify-between items-center p-8">
            <div className="space-y-3 text-center md:text-left mb-4 md:mb-0">
                <div className="flex items-center gap-4">
                    <Button
                        onClick={onBack}
                        variant="ghost"
                        className="p-2 text-white/80 hover:text-white hover:bg-white/10 transition-colors"
                    >
                        <ArrowLeft className="h-5 w-5" />
                    </Button>
                    <div className="flex items-center gap-3">
                        <ChartBar className="h-8 w-8 text-blue-400" />
                        <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-200 via-indigo-200 to-blue-300 animate-gradient">
                            {title}
                        </h1>
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    <TrendingUp className="h-4 w-4 text-blue-400" />
                    <p className="text-blue-200/80">{subtitle}</p>
                </div>
            </div>
            <Button
                onClick={onRefresh}
                className="bg-blue-500 hover:bg-blue-600 text-white flex items-center gap-2 shadow-lg hover:shadow-blue-500/30 transition-all duration-300"
                disabled={loading}
            >
                <RefreshCw className={`h-4 w-4 ${loading ? 'animate-spin' : ''}`} />
                Refresh Data
            </Button>
        </CardContent>
    </Card>
);

const MetricsGrid = ({ metrics, loading }) => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="opacity-0 fade-in" style={{ animationDelay: '0.2s' }}>
            <MetricCard
                title="Daily Active Users Ratio"
                value={`${metrics?.dauPerWau ?? '0'}%`}
                icon={Users}
                loading={loading}
                subtitle="vs Weekly Active Users"
                trend={2.5}
            />
        </div>
        <div className="opacity-0 fade-in" style={{ animationDelay: '0.3s' }}>
            <MetricCard
                title="Monthly Engagement"
                value={`${metrics?.dauPerMau ?? '0'}%`}
                icon={Activity}
                loading={loading}
                subtitle="vs Monthly Active Users"
                trend={-1.2}
            />
        </div>
        <div className="opacity-0 fade-in" style={{ animationDelay: '0.4s' }}>
            <MetricCard
                title="Overall Engagement Score"
                value={`${calculateAverageEngagement(metrics)}%`}
                icon={TrendingUp}
                loading={loading}
                subtitle="Combined Performance"
                trend={0.8}
            />
        </div>
    </div>
);

const DAUMAUAnalyticsPage = () => {

    const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID
    const navigate = useNavigate();
    const {
        data: metricsData,
        loading: metricsLoading,
        error: metricsError,
        refresh: refreshMetrics
    } = useEngagementRatios(PROPERTY_ID);



    const metrics = metricsData?.["30 days"] ?? null;

    if (metricsError) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-slate-50 to-slate-100">
                <Card className="bg-white/80 backdrop-blur-sm shadow-2xl border-red-100">
                    <CardContent className="p-8 text-center">
                        <div className="text-red-500 mb-4">
                            <Activity className="h-12 w-12 mx-auto mb-4 opacity-80" />
                            <p className="text-lg font-medium">Error loading metrics</p>
                            <p className="text-sm text-red-400 mt-2">{metricsError}</p>
                        </div>
                        <Button
                            onClick={refreshMetrics}
                            className="bg-red-500 hover:bg-red-600 text-white transition-colors"
                        >
                            Retry
                        </Button>
                    </CardContent>
                </Card>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-slate-50 via-indigo-50 to-slate-100 p-4 md:p-8 animate-gradient-slow">
            <style jsx global>{`
                @keyframes gradient {
                    0% { background-position: 0% 50%; }
                    50% { background-position: 100% 50%; }
                    100% { background-position: 0% 50%; }
                }
                .animate-gradient {
                    background-size: 200% 200%;
                    animation: gradient 6s ease infinite;
                }
                .animate-gradient-slow {
                    background-size: 200% 200%;
                    animation: gradient 15s ease infinite;
                }
                @keyframes fadeIn {
                    from { opacity: 0; transform: translateY(10px); }
                    to { opacity: 1; transform: translateY(0); }
                }
                .fade-in {
                    animation: fadeIn 0.5s ease-out forwards;
                }
            `}</style>
            <div className="max-w-7xl mx-auto space-y-8">
                <div className="opacity-0 fade-in" style={{ animationDelay: '0.1s' }}>
                    <PageHeader
                        onRefresh={refreshMetrics}
                        loading={metricsLoading}
                        onBack={() => navigate('/')}
                        title="User Activity Analysis"
                        subtitle="Active Users and Engagement Metrics"
                    />
                </div>
                <MetricsGrid
                    metrics={metrics}
                    loading={metricsLoading}
                />
            </div>
        </div>
    );
};

export default DAUMAUAnalyticsPage;