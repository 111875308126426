

import { useState, useEffect, useCallback } from 'react';
// import { useGoogleAuth } from '../utils/useGoogleAuth';
import { useAnalyticsAPI } from './useAnalyticsAPI';
// import { getHistoricalDateRange } from '../utils/dateUtils';
import { TIME_RANGES } from '../config/const';
import { useAuth } from '../Auth/AuthContext';


export const useEngagementRatios = (propertyId) => {
    const {
        getCurrentUserData, authenticate
    } = useAuth();

    const userData = getCurrentUserData();
    const accessToken = userData.accessToken

    const [data, setData] = useState({});
    // const { accessToken, authenticate } = useGoogleAuth();

    const { fetchData, loading, setLoading, error, setError } = useAnalyticsAPI(propertyId);

    const fetchRatioData = useCallback(async (token, timeRange) => {
        try {

            let ratioMetrics = {
                dauPerWau: 0,
                dauPerMau: 0
            };

            const requestBody = {
                dateRanges: [{
                    startDate: '29daysAgo',
                    endDate: 'today'
                }],
                metrics: [
                    { name: 'dauPerWau' },
                    { name: 'dauPerMau' }
                ]
            };

            const result = await fetchData('runReport', token, requestBody);
            // console.log(result)
            // Process the response
            if (result.rows && result.rows.length > 0) {
                const [dauWau, dauMau] = result.rows[0].metricValues;
                ratioMetrics.dauPerWau = parseFloat(dauWau.value) || 0;
                ratioMetrics.dauPerMau = parseFloat(dauMau.value) || 0;
            }

            return ratioMetrics;
        } catch (err) {
            console.error(`Error fetching ratio data for ${timeRange}:`, err);
            throw err;
        }
    }, [fetchData]);

    const fetchAllTimeframes = useCallback(async () => {
        if (!accessToken) {
            try {
                await authenticate();
                return;
            } catch (err) {
                setError('Authentication failed: ' + err.message);
                return;
            }
        }

        setLoading(true);
        const results = {};

        try {
            for (const timeRange of TIME_RANGES.DAU_MAU) {
                console.log('Fetching ratio data for:', timeRange);
                results[timeRange] = await fetchRatioData(accessToken, timeRange);
            }
            setData(results);
            setError(null);
        } catch (err) {
            console.error('Error fetching ratio data:', err);
            setError(err.message);
            if (err.message.includes('401') || err.message.includes('403')) {
                await authenticate().catch(console.error);
            }
        } finally {
            setLoading(false);
        }
    }, [accessToken, authenticate, fetchRatioData, setError, setLoading]);

    useEffect(() => {
        if (propertyId && !accessToken) {
            authenticate();
        }
    }, [propertyId, accessToken, authenticate]);

    useEffect(() => {
        if (accessToken) {
            fetchAllTimeframes();
        }
    }, [accessToken, fetchAllTimeframes]);

    return {
        data,
        loading,
        error,
        refresh: fetchAllTimeframes
    };
};

export default useEngagementRatios;