import React from 'react';
import { RefreshCw } from 'lucide-react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './table';
import { Button } from './button';
import { Alert, AlertDescription } from './alert';
import { Clock } from 'lucide-react';

const DataTable = ({ data, loading, error, onRetry, variant }) => {
    if (loading) {
        return (
            <div className="flex items-center justify-center p-8">
                <RefreshCw className="h-8 w-8 animate-spin text-blue-500" />
            </div>
        );
    }

    if (error) {
        return (
            <Alert variant="destructive" className="my-4">
                <AlertDescription className="flex flex-col items-center">
                    Error loading {variant} data: {error}
                    <Button onClick={onRetry} variant="outline" className="mt-2">
                        <RefreshCw className="h-4 w-4 mr-2" />
                        Retry
                    </Button>
                </AlertDescription>
            </Alert>
        );
    }

    const columns = variant === 'realtime'
        ? ['Active Users', 'Event Count', 'Page Views']
        : ['Active Users', 'New Users', 'Engagement (mins)', 'Page Views'];

    const getMetricValue = (metrics, column) => {
        const metricMap = {
            'Active Users': metrics.activeUsers,
            'Event Count': metrics.eventCount,
            'Page Views': metrics.pageViews,
            'New Users': metrics.newUsers,
            'Engagement (mins)': metrics.engagementMinutes
        };
        return metricMap[column]?.toLocaleString() || '-';
    };

    return (
        <div className="overflow-x-auto">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead>
                            <Clock className="h-4 w-4 inline mr-2" />
                            Time Range
                        </TableHead>
                        {columns.map(column => (
                            <TableHead key={column} className="text-right">{column}</TableHead>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {Object.entries(data || {}).map(([timeRange, metrics]) => (
                        <TableRow key={timeRange}>
                            <TableCell className="font-medium">{timeRange}</TableCell>
                            {columns.map(column => (
                                <TableCell key={column} className="text-right">
                                    {getMetricValue(metrics, column)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default DataTable;