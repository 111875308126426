// utils/processResponse.js
export const processAnalyticsResponse = (result, type = 'historical') => {
    if (!result?.rows) {
        return type === 'realtime' ? {
            activeUsers: 0,
            eventCount: 0,
            pageViews: 0
        } : type === 'historical' ? {
            activeUsers: 0,
            newUsers: 0,
            engagementMinutes: 0,
            pageViews: 0
        } : {

            activeUsers: 0,
            dauPerWau: 0,
            dauPerMau: 0,
            trends: [],
            totals: {
                activeUsers: 0,
                dauPerWau: 0,
                dauPerMau: 0
            }
        };
    }

    if (type === 'realtime') {
        // Process realtime data
        const metrics = result.totals?.[0]?.metricValues || [];
        return {
            activeUsers: parseInt(metrics[0]?.value || '0'),
            eventCount: parseInt(metrics[1]?.value || '0'),
            pageViews: parseInt(metrics[2]?.value || '0')
        };
    } else if (type === 'historical') {
        // Process historical data
        return result.rows.reduce((acc, row) => ({
            activeUsers: acc.activeUsers + parseInt(row.metricValues[0].value || '0'),
            newUsers: acc.newUsers + parseInt(row.metricValues[1].value || '0'),
            engagementMinutes: acc.engagementMinutes + Math.round(parseInt(row.metricValues[2].value || '0') / 60),
            pageViews: acc.pageViews + parseInt(row.metricValues[3].value || '0')
        }), {
            activeUsers: 0,
            newUsers: 0,
            engagementMinutes: 0,
            pageViews: 0
        });
    }
    else {
        // Process MAU-DAU data
        const trends = result.rows.map(row => ({
            date: row.dimensionValues[0].value,
            activeUsers: parseInt(row.metricValues[0].value || '0'),
            dauPerWau: parseFloat(row.metricValues[1].value || '0'),
            dauPerMau: parseFloat(row.metricValues[2].value || '0')
        })).sort((a, b) => b.date.localeCompare(a.date)); // Sort by date descending

        // Calculate totals from the response
        const totals = result.totals?.[0]?.metricValues || [];



        return {
            // Latest metrics (most recent day)
            activeUsers: trends[0]?.activeUsers || 0,
            dauPerWau: trends[0]?.dauPerWau || 0,
            dauPerMau: trends[0]?.dauPerMau || 0,

            // Daily trends data
            trends,

            // Period totals
            totals: {
                activeUsers: parseInt(totals[0]?.value || '0'),
                dauPerWau: parseFloat(totals[1]?.value || '0'),
                dauPerMau: parseFloat(totals[2]?.value || '0')
            },

            // Additional metadata
            rowCount: result.rowCount || 0,
            timeZone: result.metadata?.timeZone || 'UTC'
        };
    }
};
