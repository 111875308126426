
import React, { useState, useEffect } from 'react';
import { ArrowRight } from 'lucide-react';
import { useAuth } from './AuthContext';
import { getUserSession, setUserSession } from '../session/sessionUtils';

const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { loginWithGoogle } = useAuth();

    useEffect(() => {
        const currentSession = getUserSession();
        if (currentSession) {
            console.log('Current session:', currentSession.createdAt);
        }
    }, []);


    const handleGoogleLogin = async () => {
        try {
            setLoading(true);
            setError('');

            // Call the login function from our auth context
            const result = await loginWithGoogle();

            if (!result) {
                throw new Error('No user data received from Google login');
            }
            const sessionSuccess = setUserSession(result)

            if (!sessionSuccess) {
                console.error('Failed to set session data after Google login');
                setError('Login successful but failed to initialize session.');
                return;
            }

            // Optional: Redirect or perform additional actions after successful login
            // router.push('/dashboard');

        } catch (err) {
            console.error('Error during Google login:', err);
            setError(err.message || 'Failed to sign in with Google.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-100 via-purple-50 to-pink-100 p-4">
            <div className="max-w-md w-full perspective-1000">
                <div className="relative transform-gpu transition-all duration-700 hover:rotate-y-10">
                    {/* Main Card */}
                    <div className="bg-white rounded-3xl shadow-2xl overflow-hidden transform transition-all duration-500 hover:shadow-indigo-200 hover:scale-[1.02]">
                        {/* Animated Background Header */}
                        <div className="relative h-64 bg-gradient-to-r from-indigo-600 to-purple-600 overflow-hidden">
                            {/* Animated circles */}
                            <div className="absolute -top-12 -left-12 w-64 h-64 bg-purple-500/30 rounded-full blur-3xl animate-pulse"></div>
                            <div className="absolute -bottom-12 -right-12 w-64 h-64 bg-indigo-500/30 rounded-full blur-3xl animate-pulse delay-700"></div>

                            {/* Logo Container */}
                            <div className="absolute top-6 right-6">
                                <div className="relative group">
                                    <div className="absolute -inset-2 bg-white/20 rounded-full blur transition duration-700 group-hover:bg-indigo-400/40 group-hover:duration-200"></div>
                                    <div className="relative">
                                        <img
                                            src="/logo.png"
                                            alt="ABBA Logo"
                                            className="h-24 w-24 object-contain transform transition-all duration-500 group-hover:scale-110 "
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `data:image/svg+xml,${encodeURIComponent(`
                          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="64" height="64" rx="32" fill="white"/>
                            <text x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="20" font-weight="bold" fill="#4F46E5">
                              ABBA
                            </text>
                          </svg>
                        `)}`;
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Welcome Text */}
                            <div className="absolute bottom-12 left-0 right-0 text-center">
                                <h2 className="text-white text-4xl font-bold mb-4 opacity-0 animate-fade-in-up">
                                    Welcome
                                </h2>
                                <div className="relative inline-block">
                                    <div className="relative px-8 py-2 bg-white/10 rounded-full border border-white/30 backdrop-blur-sm transform transition-all duration-500 hover:scale-105">
                                        <span className="absolute -left-1 top-1/2 -translate-y-1/2 text-white/60 animate-pulse">✦</span>
                                        <span className="absolute -right-1 top-1/2 -translate-y-1/2 text-white/60 animate-pulse">✦</span>
                                        <span className="text-white text-lg font-medium tracking-wider uppercase animate-pulse">
                                            Entrance Dashboard
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Login Content */}
                        <div className="px-8 py-10 space-y-6">
                            {/* Google Login Button */}
                            <div className="transform transition-all duration-500 hover:scale-105">
                                <button
                                    onClick={handleGoogleLogin}
                                    disabled={loading}
                                    className="w-full flex items-center justify-center px-6 py-4 border border-gray-200 rounded-xl hover:bg-gray-50 transition-all duration-300 group relative overflow-hidden bg-white"
                                >
                                    <div className="absolute inset-0 w-3 bg-gradient-to-r from-indigo-500 to-purple-500 transition-all duration-[400ms] ease-out -translate-x-full group-hover:translate-x-full"></div>
                                    {loading ? (
                                        <div className="flex items-center gap-3">
                                            <div className="w-5 h-5 border-t-2 border-b-2 border-indigo-600 rounded-full animate-spin"></div>
                                            <span className="text-gray-600 font-medium">Signing in...</span>
                                        </div>
                                    ) : (
                                        <div className="flex items-center gap-3">
                                            <svg className="w-6 h-6" viewBox="0 0 24 24">
                                                <path
                                                    fill="#4285F4"
                                                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                                                />
                                                <path
                                                    fill="#34A853"
                                                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                                                />
                                                <path
                                                    fill="#FBBC05"
                                                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                                                />
                                                <path
                                                    fill="#EA4335"
                                                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                                                />
                                            </svg>
                                            <span className="text-gray-700 font-medium text-lg">
                                                Continue with Google
                                            </span>
                                            <ArrowRight className="w-5 h-5 text-gray-400 transform transition-all duration-300 group-hover:translate-x-1" />
                                        </div>
                                    )}
                                </button>
                            </div>

                            {/* Error Message */}
                            {error && (
                                <div className="text-red-500 text-center animate-fade-in">
                                    {error}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Add required styles for animations */}
            <style jsx global>{`
        .perspective-1000 {
          perspective: 1000px;
        }
        .rotate-y-10 {
          transform: rotateY(10deg);
        }
        @keyframes fade-in-up {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .animate-fade-in-up {
          animation: fade-in-up 0.6s ease-out forwards;
        }
        .animate-fade-in {
          animation: fade-in 0.3s ease-out forwards;
        }
        @keyframes fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
        </div>
    );
};

export default LoginPage;